(function ($, window, App) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'doAjax');
  /** @type {Function} Warn helper */
  const { warn } = App.components.Debugger(debug, 'doAjax');

  /**
   * Global array to store every AJAX request made to avoid duplicates
   * @type {Array}
   */
  const ajaxRequests = [];

  /** @type {Function} Empty function helper */
  const noop = function noop() {};

  /**
   * Test if a given value is of type `function`
   * @param  {Function} fn The value to test
   * @return {Boolean}     Is it a function?
   */
  function isFunction(fn) {
    return typeof fn === 'function';
  }

  /**
   * Do an ajax call to the admin ajax controller
   * @param  {Object} options        The custom options for the ajax request
   * @param  {Function} callbacks.onDone   The done callback function
   * @param  {Function} callbacks.onFail   The fail callback function
   * @param  {Function} callbacks.onAlways The always callback function
   */
  function doAjax(options, callbacks) {
    // Merge default and custom options
    const opts = $.extend(
      true,
      {},
      {
        xhrFields: { withCredentials: true },
        data: {
          ajax: true,
          // eslint-disable-next-line
					static_token: window.prestashop.static_token,
          token: window.prestashop.token,
        },
      },
      options
    );

    // Check if callbacks are functions
    const done = isFunction(callbacks.onDone) ? callbacks.onDone : noop;
    const fail = isFunction(callbacks.onFail) ? callbacks.onFail : noop;
    const always = isFunction(callbacks.onAlways) ? callbacks.onAlways : noop;

    // Check if request is a duplicate of a request already running
    const stringifiedOpts = JSON.stringify(opts);
    if (ajaxRequests.indexOf(stringifiedOpts) > -1) {
      log(opts, 'is a duplicate request, stopping here.');
      return;
    }
    ajaxRequests.push(stringifiedOpts);

    // Add `rand` parameter
    opts.data.rand = new Date().getTime();

    log(opts);

    $.ajax(opts)
      .done(function (response) {
        log('done', opts, response);
        done(response);
      })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        log('fail', opts, XMLHttpRequest, textStatus, errorThrown);
        fail(XMLHttpRequest, textStatus, errorThrown);
      })
      .always(function () {
        // Remove ajax request from the store to be able to replay them.
        const index = ajaxRequests.indexOf(stringifiedOpts);
        ajaxRequests.splice(index, 1);

        log('always', opts);
        always();
      });
  }

  // Expose it globally
  window.doAjax = function (options, callback) {
    /* eslint-disable-next-line */
		warn('The `window.doAjax` method is deprecated, please use the `App.components.doAjax` method.');
    doAjax(options, callback);
  };
  App.components.doAjax = doAjax;
})(jQuery, window, App);
